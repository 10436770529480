import React, { PureComponent } from 'react';
import MnTable from '../MnTable';

class TableTest extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      data: [],
      dataURL: "http://localhost/users/user_api/users",
      columns: ['id','fname', 'title', 'email', 'mobile'],
      commonSearch: true,
      commonSearchKey: "",
      searchColumns: ['id','fname', 'title', 'email', 'mobile'],
      sortables: [],
      defaultSortColumn: "",
      defaultSortValue: "ASC",
      pageSize: 50,
      pageSizeValues: [],
      pageNumber: 1,
      firstPage: false,
      previousPage: false,
      nextPage: false,
      lastPage: false
    };
  }

  componentWillMount = () => {
    console.log('TableTest will mount');
  }

  componentDidMount = () => {
    console.log('TableTest mounted');
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('TableTest will receive props', nextProps);
  }

  componentWillUpdate = (nextProps, nextState) => {
    console.log('TableTest will update', nextProps, nextState);
  }

  componentDidUpdate = () => {
    console.log('TableTest did update');
  }

  componentWillUnmount = () => {
    console.log('TableTest will unmount');
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div className="TableTestWrapper">
        {/*  */}
        <MnTable dataURL={this.state.dataURL} title='Users Table' columnNames={this.state.columns} searchColumns={this.state.searchColumns} />
        {/* <MnTable dataURL={this.state.dataURL} columnNames={this.state.columns} title='Users Table' /> */}
        {/* <MnTable dataURL={this.state.dataURL} columnNames={this.state.columns} title='Users Table' searchColumns={this.state.searchColumns} /> */}
      </div>      
    );
  }
}

export default TableTest;
