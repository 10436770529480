import React, {Component} from "react";
import "../App.css";
import { BsPlus, BsPencilSquare, BsX } from 'react-icons/bs';
import EditTodo from "./EditTodo";
import ListGroup from 'react-bootstrap/ListGroup';
//import Button from 'react-bootstrap/Button';


class Todo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditTodo:false,
      selectTodo: {},
      selectedTodoId: 1,
      assignUsers:this.props.assignUsers,
    };
    //console.log('Ikkada Users:' , this.props.assignUsers);
  }

  addSubTodo = (todoId,todo) => {
   // console.log("In todo Page:", todoId);
    //debugger;
    this.props.addSubTodoFromTodoList(todoId,todo);
  };
  closeEditTodo = () => {
    this.setState({
      openEditTodo: false
    });
  };

  handleEditTodo = todo => {
    if(this.state.openEditTodo){
      this.setState({
        selectTodo: {todo},
        selectedTodoId: "",
        openEditTodo: false,
      });
    }else{
      this.setState({
        selectTodo: todo,
        selectedTodoId: todo.id,
        openEditTodo: true
      });
    }
  };
  render() {
    const { todo } = this.props;
    //console.log("In todo page",this.props);
    //console.log("all assinged users", this.state.assignUsers)
    //console.log("conflictMsg",this.props.conflict);
    return (
      <ListGroup.Item className="todoList todoColor">
        <div className="todoContainer" id={"todo_"+todo.structure}>
          <div className="todo">
          {this.state.openEditTodo &&
            <div>
              {todo.structure}
            </div>
          }
          {!this.state.openEditTodo &&
            <div onClick={() => this.handleEditTodo(todo)}>
              <div className="todoTitle">
                {" "}
                <span className="text-muted">
                  {todo.structure}{"."}
                </span> {todo.name && todo.name!=""?todo.name:<span style={{color:"gray"}}>This is new todo, please enter a name</span>}{" "}
              </div>
              <div className="todoDesc">
                {" "}{todo.description}{" "}
              </div>
            </div>
          }
          </div>
          <div className="constraints">
            {todo.status !=5 ?(
              <div className="">
              {todo.assign_to!="0" && todo.assign_to?(
               <h3 className="badge badge-primary font-size mr-2 p-2">Assigned</h3>
              ): ""}
              <button onClick={() => this.addSubTodo(todo.id,todo)} className="ms-2 btn btn-outline-success font-size"><BsPlus size={24} />Sub Todo</button>
              </div>
              ):(<div className="p-2"><h3 className="badge badge-success font-size">Completed</h3></div>)
            }
          </div>
        </div>

        <div className="editTodo">
        {this.state.openEditTodo && this.state.selectedTodoId===todo.id &&
          <EditTodo
              deleteTodo = {(todoId, todo) => this.props.deleteTodo(todoId,todo)}
              useremail= {this.props.useremail}
              selectedConstraintId = {this.props.selectedConstraintId}
              closeConflict = {()=>this.props.closeConflict()}
              assignUsers={this.state.assignUsers}
              id={this.state.selectedTodoId}
              data={this.state.selectTodo}
              closeEditTodo={() => this.closeEditTodo()}
              conflict={this.props.conflict}
              conflictMsg={this.props.conflictMsg}
              updateTodo={(todoId, newObj) => this.props.updateTodo(todoId, newObj)}
              getSubtaskStatus = {(todoId)=>this.props.getSubtaskStatus(this.state.selectedTodoId)}
              checkAvailableConstraintLimit={(
                todoId,
                parentId,
                constraintId,
                newValue
              ) =>
                this.props.checkAvailableConstraintLimit(
                  todoId,
                  parentId,
                  constraintId,
                  newValue
                )
              }
              type3AvailableConstraintLimit={(
                todoId,
                parentId,
                constraintId,
                newValue
              ) =>
                this.props.type3AvailableConstraintLimit(
                  todoId,
                  parentId,
                  constraintId,
                  newValue
                )
              }
            />
        }
        </div>
        <ListGroup>
          {todo.subTask && todo.subTask.map(todo => (
            <Todo
              deleteTodo = {(todoId, todo)=>this.props.deleteTodo(todoId,todo)}
                useremail= {this.props.useremail}

              closeConflict = {()=>this.props.closeConflict()}
              conflict={this.props.conflict}
              assignUsers={this.state.assignUsers}
              selectedConstraintId = {this.props.selectedConstraintId}
              conflictMsg ={this.props.conflictMsg}
              todo={todo}
              assignUsers={this.state.assignUsers}
              key={todo.id}
              handleEditTodo={todoObj => this.handleEditTodo(todoObj)}
              addSubTodoFromTodoList={(newTodoId,newTodo) => this.props.addSubTodoFromTodoList(newTodoId,newTodo)}
              updateTodo={(todoId, newObj) => this.props.updateTodo(todoId, newObj)}
              getSubtaskStatus = {(todoId)=>this.props.getSubtaskStatus(todoId)}

              checkAvailableConstraintLimit={(
                todoId,
                parentId,
                constraintId,
                newValue
              ) =>
                this.props.checkAvailableConstraintLimit(
                  todoId,
                  parentId,
                  constraintId,
                  newValue
                )
              }
              type3AvailableConstraintLimit={(
                todoId,
                parentId,
                constraintId,
                newValue
              ) =>
                this.props.type3AvailableConstraintLimit(
                  todoId,
                  parentId,
                  constraintId,
                  newValue
                )
              }
          />
          ))}
        </ListGroup>
      </ListGroup.Item>
    );
  }
}
export default Todo;
