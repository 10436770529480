import {
  RESETPASSWORD2_REQUEST,
  RESETPASSWORD2_SUCCESS,
  RESETPASSWORD2_FAILURE,
  RESETPASSWORD2UPDATE_REQUEST,
  RESETPASSWORD2UPDATE_SUCCESS,
  RESETPASSWORD2UPDATE_FAILURE,
} from "./resetPassword2Types";
const initialState = {
  resetPassword2Loading: false,
  tokenState: false,
  errorMsg: "",
  errorStatus:0,
  updateStatus:0,
};
const resetPassword2Reducer = (state = initialState, action) => {
  switch (action.type) {
	case RESETPASSWORD2_REQUEST:
	  return {
		resetPassword2Loading: true,
		tokenState: false,
		errorMsg: ""
	  };
	case RESETPASSWORD2_SUCCESS:
	  return {
		resetPassword2Loading: false,
		tokenState: true,
		errorStatus:action.payload.status,
		errorMsg: ""
	  };
	case RESETPASSWORD2_FAILURE:
	  return {
		resetPassword2Loading: false,
		tokenState: false,
		errorMsg: action.payload.data,
		errorStatus:action.payload.status,
	  };
	  case RESETPASSWORD2UPDATE_REQUEST:
		return {
		  ...state,
		  resetPassword2Loading: true,
		  errorMsg: ""
		};
	  case RESETPASSWORD2UPDATE_SUCCESS:
		return {
		  ...state,
		  resetPassword2Loading: false,
		  updateStatus: action.payload.status,
		  errorMsg: ""
		};
	  case RESETPASSWORD2UPDATE_FAILURE:
		return {
		  ...state,
		  resetPassword2Loading: false,
		  updateStatus: action.payload.status,
		  errorMsg: action.payload.data
		};
	default:
	  return state;
  }
};

export default resetPassword2Reducer;
