import {
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILURE,
  UPDATE_RESET_PASSWORD_STATUS
} from "./resetPasswordTypes";
const initialState = {
  resetPasswordLoading: false,
  loginState: false,
  userData: [],
  resetPasswordErrorMsg: "",
  resetPasswordStatusCode: 0
};
const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESETPASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordLoading: true
      };
    case RESETPASSWORD_SUCCESS:
      return {
        resetPasswordLoading: false,
        loginState: true,
        userData: action.payload.data,
        resetPasswordStatusCode: action.payload.status,
        resetPasswordErrorMsg: ""
      };
    case RESETPASSWORD_FAILURE:
      return {
        resetPasswordLoading: false,
        loginState: false,
        userData: [],
        resetPasswordStatusCode: action.payload.status,
        resetPasswordErrorMsg: action.payload.data
      };
    case UPDATE_RESET_PASSWORD_STATUS:
      return{
        ...state,
        resetPasswordStatusCode:action.payload
      }
    default:
      return state;
  }
};

export default resetPasswordReducer;
