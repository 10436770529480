import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS
} from "./loginTypes";
const initialState = {
  loginState: false,
  loading: false,
  statusCode: 0,
  userData: false,
  errorMsg: ""
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        loginState: true,
        userData: action.payload.data,
        rememberme:action.payload.rememberme,
        statusCode: action.payload.status,
        errorMsg: ""
      };
    case LOGIN_FAILURE:
      return {
        loading: false,
        loginState: false,
        userData: "",
        statusCode: action.payload.status,
        errorMsg: action.payload.data
      };


      case LOGOUT_SUCCESS:
        return {
          loading: false,
          loginState: false,
          userData: "",
          errorMsg: ""
        };
    default:
      return state;
  }
};

export default loginReducer;
