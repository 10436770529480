import React, { Component } from "react";
import "../css/footer.css";
import "../css/style.css";
import "../css/login.css";

class resetPasswordNotice extends Component {
	render() {
		return (
			<div id="reset" className="page">
				<div className="page-header">
					<span className="logo">[<span className="logo-mn">mn</span>]Shop</span>
				</div>
				<div className="page-body">
				    <div>		
            <h4>Mail Sent, Check Mail for Reset Password Progress</h4>
				    </div>
				</div>	
				<div className="page-footer text-center">
					<p className="copyrights">
						<span>&#169;&nbsp;[<span className="mn-color">mn</span>]Shop</span> 2020<br/>
						Developed by&nbsp;[<span className="mn-color">mn</span>]media<strong>net</strong>
					</p>
				</div>
			</div>	
		);
	}
}
export default resetPasswordNotice;

