import MNSTORE from "../constants";

//LOGIN
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// LOGOUT

//export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
//export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

/**
 * @constant
 * @method POST
 * @description This is used for end point of the login webservice
 */
export const MNSTORE_LOGIN = MNSTORE + "login";

/**
 * @constant
 * @method POST
 * @description This is used for end point of the login webservice
 */
// export const MNSTORE_LOGOUT = MNSTORE + "logout";
