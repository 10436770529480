import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsX , BsCalendar3, BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ProgressBar } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import {ServiceURL} from "../redux/constants";
import Alert from 'react-bootstrap/Alert';
import CreatableSelect from 'react-select/creatable';



class EditTodo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      users: this.props.assignUsers,
      progress:0,
      error: this.props.conflict,
      more_options:false,
      more_label:"more options",
      selectedOption:{
        id:"",
        userid:"",
        objectid:"",
        active:"",
        value:"",
        label:"",

      },
      //show:"display: hidden",
    };
    console.log("users",this.props.useremail);
  }

  handleTodoName = (event, todoId) => {
    const field_name = "name";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.name = field_value;
    this.props.updateTodo(newObj.id, newObj);
  }
  handleDelete = ()=>{
    const todoId = this.props.id;
    const {data} = this.props;
    if(data.subTask.length>0){
      alert("Please delete sub todos first to delete the main todo..");
    }else{
      if(window.confirm("Are you Sure ?")){
        this.props.deleteTodo(todoId, data );
      }
    }
  }
  handleEstimatedTime1 = (event, todoId)=>{
    const field_name = "estimated_time_1";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    //console.log("ededo",field_name);
    let newObj = data;
    newObj.estimated_time_1 = field_value;
    this.props.updateTodo(newObj.id, newObj);
    //console.log("name", event.target.name);
  }
  handleEstimatedTime2 = (event, todoId)=>{
    const field_name = "estimated_time_2";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    //console.log("ededo",field_name);
    let newObj = data;
    newObj.estimated_time_2 = field_value;
    this.props.updateTodo(newObj.id, newObj);
    //console.log("name", event.target.name);
  }
  handleTodoStatus = (event, todoId) => {
    const field_name = "status";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    if(event.target.value==5){
    if(this.props.getSubtaskStatus(todoId)){
      console.log(this.props.getSubtaskStatus(todoId));
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.status = field_value;
    this.props.updateTodo(newObj.id, newObj);
  }
}else{
        var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.status = field_value;
    this.props.updateTodo(newObj.id, newObj);
}
  }
  handleProgress = (event, todoId) =>{
    const field_name = "progress";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    this.setState({
      progress: event.target.value
    });
    let newObj = data;
    newObj.progress = field_value;
    this.props.updateTodo(newObj.id, newObj);
  }
  handleSpentTime = (event, todoId)=>{
    const field_name = "spent_time";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.spent_time = field_value;
    this.props.updateTodo(newObj.id, newObj);
  }
  handlePriority = (event, todoId)=>{
    const field_name = "priority";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.priority = field_value;
    this.props.updateTodo(newObj.id, newObj);
  }
  handleTodoDescription = (event, todoId) => {
    const field_name = "description";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.description = field_value;
    this.props.updateTodo(newObj.id, newObj);
  };
  handleTodoStartDate = (event, todoId) => {
    const field_name = "start_date";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.start_date = field_value;
    this.props.updateTodo(newObj.id, newObj);
  };
  handleTodoEndDate = (event, todoId) => {
    console.log(event.target);
    const field_name = "end_date";
    const field_value = event.target.value;
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    axios.post(editURL, {field_name, field_value, todo_id});
    const { data } = this.props;
    let newObj = data;
    newObj.end_date = field_value;
    this.props.updateTodo(newObj.id, newObj);
  };
  handleTodoAssign = (event, todoId) =>{
    console.log("event",event);
      let field_value = 0;
    const field_name = "assign_to";
    const assignee_mail = this.props.useremail;
    let assign_mail = "";
    if(event){
      assign_mail = event.userid;
      field_value = event.id;
      this.setState({
        selectedOption:event,
      });
    }
    const todo_id = this.props.id;
    var editURL = ServiceURL+"todo_api/todoUpdate";
    const{data}= this.props;
    let newObj = data;
    axios.post(editURL,{field_name,field_value,todo_id, newObj, assign_mail, assignee_mail});
    newObj.assign_to = field_value;
    this.props.updateTodo(newObj.id, newObj);
  };
  handleConstraintType = (event)=>{
    const type = event.target.value;
    const field_value = type;
    const editURL = ServiceURL+"todo_api/todoUpdate";
    const todo_id = this.props.id;
    const {data} = this.props;
    let newObj  =data;
    newObj.constraint_type = type;
    const field_name = 'constraint_type';
    axios.post(editURL,{field_name, field_value, todo_id});
   // console.log({type});
    this.setState({
      show: "display: ",
    });
    if(type===1){
      this.setState({
        time_id:1,
        budget_id: 2,
      });
    }else if(type===2){
      this.setState({
        time_id:3,
        budget_id:4,
      });
    }else if(type===3){
      this.setState({
        time_id:5,
        budget_id:6,
      });
    }else if(type==='0'){
      this.setState({
        time_id:"",
        budget_id:"",
        show: "display: hidden",
      });
    }
    this.props.updateTodo(newObj.id, newObj);
  }
  handleinvite=(event)=>{
    if(event.key==='Enter'){
      console.log("enter press chesava :",event.value);
    }
  }
  userCreate=(event)=>{
    const email = event;
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
          console.log("email verification aiindi");
          //emailValid = false;
          this.setState({
            emailConflict: false,
            emailConflictMsg: "Please Enter valid email",
          });
        }else{
          const inviteUrl = ServiceURL+"todo_api/sendInviteMail";
          axios.post(inviteUrl,{email}).then(response=>{

          });
        }

  }
    handleMoreOptions=()=>{
      if(!this.state.more_options){
      this.setState({
          more_options:true,
          more_label: "less options",
        });
      }else{
        this.setState({
          more_options:false,
          more_label:"more options",
        })
      }
    }
  handleConstraint = (event, constraintId) => {
    const { data } = this.props;
    let newObj = data;
    const constraintValue = parseInt(event.target.value);
    let exceed='';
    let type3exceed='';
    //console.log("selected id", typeof this.props.selectedConstraintId);
    if(this.props.selectedConstraintId ==="1"){
    exceed = this.props.checkAvailableConstraintLimit(
      data.id,
      data.parent,
      constraintId,
      constraintValue
    );
    }
    if(this.props.selectedConstraintId==='3'){
    console.log("selected id", this.props.selectedConstraintId);

    type3exceed = this.props.type3AvailableConstraintLimit(
      data.id,
      data.parent,
      constraintId,
      constraintValue
    );
    }
    //type3AvailableConstraintLimit

    for (let i = 0; i < newObj.constraints.length; i++) {
      if (newObj.constraints[i].id === constraintId) {
        if (this.props.selectedConstraintId === 3 && !type3exceed) {
          newObj.constraints[i].value = constraintValue;
        } else if (this.props.selectedConstraintId === 2) {
          newObj.constraints[i].value = constraintValue;
        } else if (!exceed) {
          newObj.constraints[i].value = constraintValue;
        }
        var updateURL = ServiceURL+"todo_api/todoUpdate";
        const field_name = "constraints";
        const field_value=newObj.constraints;
        const todo_id= data.id;
        axios.post(updateURL,{field_name, field_value, todo_id});
        this.props.updateTodo(newObj.id, newObj);
      }
    }
  };

  render() {

    const { data, conflict, conflictMsg } = this.props;
    const assingusers = this.state.users;
    if(assingusers){
    assingusers.map((user)=>{
      if(user.id==data.assign_to){
        data.assign_to=user;
      }
    })
    }
    let con_unit = '';
    if(this.props.selectedConstraintId===3){
      con_unit = "%";
    }
    /*for(let i=0;i<=this.state.users.length;i++){
      if(this.state.users[i].id==data.assign_to){
        data.assign_to = this.state.users[i];
      }
    }*/
   // console.log("Todo Data", data.assign_to);
    //const constraints = JSON.parse(data.constraints);
   console.log("users", data);
    return (
      <Card className="mb-2">
        {/* <Card.Header>
          <div className="todoEdit-title">
            <Row>
              <Col><h3 className="pt-1">{data.structure}{" "}</h3></Col>
              <Col><span className="btn btn-outline-danger float-right" title="Close Window" onClick={() => this.props.closeEditTodo()}><BsX size={22} /></span>{" "}</Col>
            </Row>
          </div>
        </Card.Header> */}
        <Card.Body>
          <div className="errorMsg">
            {conflict ? conflictMsg :""}
            {conflict ? <button onClick={() => this.props.closeConflict()}><BsX size={18} /></button> : ""}
          </div>
         <div><span className="btn btn-outline-danger float-right" title="Close Window" onClick={() => this.props.closeEditTodo()}><BsX size={22} /></span>{" "}</div>
          <Form>
            <Row>
                <Col lg={6} className="pr-2">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    {/* <Form.Label>Name&nbsp;:&nbsp;</Form.Label> */}
                    <Form.Control className="inputEnable" size="lg" type="text" placeholder="Enter your Todo name or Headline here" value={data.name|| ''} onChange={this.handleTodoName} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="pl-2 pr-2">
                <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {/* <Form.Label>Description&nbsp;:&nbsp;</Form.Label> */}
                        <Form.Control className="descEnable" size="lg" as="textarea" rows = {4} placeholder="Please Enter Todo Description" value={data.description||''} onChange={this.handleTodoDescription} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="pl-2 pr-2">
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Assign To&nbsp;:&nbsp;</Form.Label>
                        <CreatableSelect
                            isClearable={true}
                            closeMenuOnSelect={true}
                            getOptionValue={(user)=>user.userid}
                            id="usersFilter"
                            name="usersFilter"
                            onChange={this.handleTodoAssign}
                            onCreateOption={this.userCreate}
                            options={this.state.users}
                            placeholder="Type email here or click to select"
                            value={data.assign_to}
                        />
                        <div className="text-danger">
                        {this.state.emailConflictMsg}
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Estimated time(by Author)&nbsp;:&nbsp;</Form.Label>
                    <Form.Control size="lg" type="text" className="text-right" name="estimated_time_1" value={data.estimated_time_1||''} onChange={this.handleEstimatedTime1}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Start Date&nbsp;:&nbsp;</Form.Label>
                    <InputGroup size="lg">
                      <Form.Control type="date" placeholder="dd/mm/yyyy" value={data.start_date||''} onChange={this.handleTodoStartDate} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2"><BsCalendar3 /></InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Todo Progress&nbsp;:&nbsp;</Form.Label>
                        <Form.Control size="lg" type="text" value={data.progress||''} onChange={this.handleProgress}/>
                        <ProgressBar className="mt-2" now={data.progress} label={`${data.progress}%`} />
                    </Form.Group>
                </Col>
            </Row>
            {this.state.more_options &&
            <Row className="pl-2 pr-2">
                {data.constraints && data.constraints.map(constraint => (
                    <Col key={constraint.id}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{constraint.name}&nbsp;:&nbsp;</Form.Label>
                            <InputGroup size="lg">
                            <Form.Control className="text-end" type="text" placeholder="constraintInput" value={isNaN(constraint.value) ? 0 : parseInt(constraint.value)} onChange={event =>
                                this.handleConstraint(event, constraint.id) } aria-describedby="basic-addon2" />
                            <InputGroup.Text id="basic-addon2">{con_unit+""+constraint.units}</InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                ))}
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Todo Status&nbsp;:&nbsp;</Form.Label>
                    <Form.Control as="select" size="lg" value={data.status||''} onChange={this.handleTodoStatus}>
                      <option value="0">Select</option>
                      <option value="1">New</option>
                      <option value="2">In-development</option>
                      <option value="3">Developed</option>
                      <option value="4">Review</option>
                      <option value="5">Close</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>End Date&nbsp;:&nbsp;</Form.Label>
                    <InputGroup size="lg">
                      <Form.Control type="date" placeholder="dd/mm/yyyy" value={data.end_date||''} onChange={this.handleTodoEndDate}  aria-label="Recipient's username" aria-describedby="basic-addon2" />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2"><BsCalendar3 /></InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            }
            {this.state.more_options &&
              <Row className="pl-2 pr-2">

                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Estimated time(by Assignee)&nbsp;:&nbsp;</Form.Label>
                    <Form.Control size="lg" type="text" className="text-right" name="estimated_time_2" value={data.estimated_time_2||''} onChange={this.handleEstimatedTime2}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Spent time(In Hours)&nbsp;:&nbsp;</Form.Label>
                    <Form.Control size="lg" type="text" className="text-right" value={data.spent_time||''} onChange={this.handleSpentTime}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Priority&nbsp;:&nbsp;</Form.Label>
                    <Form.Control size="lg" as="select" value={data.priority||''} onChange={this.handlePriority}>
                      <option value="0">Select</option>
                      <option value="1">Low</option>
                      <option value="2">Normal</option>
                      <option value="3">High</option>
                      <option value="4">Immediate</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            }
            {/* {this.state.more_options &&
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Description&nbsp;:&nbsp;</Form.Label>
                    <Form.Control size="lg" as="textarea" placeholder="Todo Description" value={data.description||''} onChange={this.handleTodoDescription} />
                  </Form.Group>
                </Col>
              </Row>
              } */}
          </Form>
          <div className="text-end">
            <button className="btn btn-outline-secondary btn-lg" onClick={this.handleMoreOptions}>{this.state.more_options && <BsChevronUp size={18} />}{!this.state.more_options && <BsChevronDown size={18} />}{this.state.more_label}</button>{" "}
            <button className="btn btn-outline-danger btn-lg" onClick={this.handleDelete}><BsX size={18} />Delete</button>{" "}
            {/* <button className="btn btn-danger btn-lg" onClick={() => this.props.closeEditTodo()}><BsX size={18} />close</button>{" "} */}
          </div>
        </Card.Body>
      </Card>
    );
  }
}
export default EditTodo;