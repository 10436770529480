import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Login from "./components/login";
import Register from "./components/register";
import ResetPassword from "./components/resetPassword";
import ResetPassword2Component from "./components/resetPassword2";
import Dashboard from "./components/dashboard";
import TodoList from "./components/TodoList";
import Settings from "./components/Settings";
import resetPasswordNotice from "./components/resetPasswordNotice";
import TableTest from "./components/TableTest";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact strict path="/" component={Login} />
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/register" component={Register} />
            <Route
              exact
              strict
              path="/ResetPassword"
              component={ResetPassword}
            />
            <Route
              exact
              strict
              path="/resetpassword2/"
              component={ResetPassword2Component}
            />
            <Route
              exact
              strict
              path="/resetpassword2/:token"
              component={ResetPassword2Component}
            />
            <Route exact strict path="/dashboard" component={Dashboard} />
            <Route exact strict path="/TodoList" component={TodoList} />
            <Route exact strict path="/Settings" component={Settings} />
            <Route
              exact
              strict
              path="/notice"
              component={resetPasswordNotice}
            />
            <Route exact strict path="/table" component={TableTest} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
