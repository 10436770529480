import MNSTORE from "../constants";

export const RESETPASSWORD_REQUEST = "RESETPASSWORD_REQUEST";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";
export const UPDATE_RESET_PASSWORD_STATUS = 'UPDATE_RESET_PASSWORD_STATUS';

/**
 * @constant
 * @method POST
 * @description This is used for end point of the resetpassword webservice
 */
export const MNSTORE_RESETPASSWORD = MNSTORE + "reset";
