import React, { Component } from 'react';
import axios from 'axios';
import Login from './login';
import MNSTORE from "../redux/constants";
import {ServiceURL} from '../redux/constants';
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/registration.css";
import "../css/footer.css";
import "../css/style.css";
import "../App.css";

class Register extends Component {
  constructor(props){
    super(props);
    this.state={
      name:'',
      email:'',
      password:'',
      userdata:'',
      active:"1",
      emailConflict:true,
      emailConflictMsg:'',
      passwordConflict:true,
      passwordConflictMsg:'',
      successMsg:'',
    }
  }
  componentDidMount(){
    var loginURL = MNSTORE+"login";
    var data = "userId=sivaji@medianet-home.de&password=Sivaji@123";
    axios.post(loginURL,data).then(response=>{
      //console.log("ikkada data",response);
      this.setState({
        userdata:response.data
      });
    });
  }
  addValues= (event)=>  {
   // console.log(event);
    const field_name = event.target.name;
    const field_value = event.target.value;
    if(field_value!=null){
      if(field_name==="name"){
      this.setState({
        name: field_value
      });
      }else if(field_name==="email"){
        this.setState({
        email: field_value
      });

      }else if(field_name==="password"){
        this.setState({
        password: field_value
      });
      }
      }

    }
    updateEmail = event => {
    this.setState({
      email: event.target.value
    });
  };

  updatePassword = event => {
    this.setState({
      password: event.target.value
    });
  };


  handleClick=(event)=>{
    console.log("Ikkada vundi",event);
    console.log("values",this.state.name,this.state.email,this.state.password);
    var formData = new FormData();
    formData.append("userid",this.state.email);
    formData.append("password",this.state.password);
    formData.append("active",this.state.active);
    var registerURL = MNSTORE+"user";
    const userid = this.state.email;
    const password = this.state.password;
    const active = "1";
    let emailValid = true;
    let passwordValid = true;

    if(this.state.email==''){
          emailValid = false;
      this.setState({
        emailConflict: false,
        emailConflictMsg:"Email field cannot be empty."
      });
    }
    if(this.state.email!==""){
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(this.state.email)) {
          console.log("email verification aiindi");
          emailValid = false;
          this.setState({
            emailConflict: false,
            emailConflictMsg: "Please Enter valid email",
          });
          /*isValid = false;
          errors["email"] = "Please enter valid email address.";*/
        }
    }
    if(this.state.password==''){
      passwordValid=false;
     this.setState({
        passwordConflict: false,
        passwordConflictMsg:"Password field cannot be empty."
      });
    }
    if(this.state.password!==""){
      var pattern = new RegExp(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/i);
        if(!pattern.test(this.state.password)){
          passwordValid=false;
          this.setState({
            passwordConflict: false,
            passwordConflictMsg: "Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
          });
        }
    }
    console.log("email error",this.state.emailConflict);
    if(emailValid&&passwordValid){
    console.log("userdata",this.state.userdata);
    //To be done:check for empty values before hitting submit
    var self = this;
    var headers = {
      "mnauthorization": this.state.userdata,
        "app-id-header":"2",
        "app-secret-header":"143",
    }
    var payload={
    "userid":this.state.email,
    "password":this.state.password,
    "active":1,
    }
    axios({
      method:"post",
      url:registerURL,
      data: formData,
      headers:headers,
    })
    .then(response=> {

        this.setState({
          successMsg:"Registered Sucessfully, please goto login page to login..",
          emailConflictMsg:'',
          passwordConflictMsg:'',
        });

     console.log("response",response);
   })
   .catch(function (error) {
     console.log("error",error);
   });
   }
  }
  render() {
    return (
      <div id="register" className="page">
        <div className="page-header">
          <span className="logo">
            [<span className="mn-color">mn</span>]Todo
          </span>
          <span className="page-name-delimiter">-</span>
          <span className="page-name">Log in</span>
        </div>
        <div className="page-body">
          <div id="loginForm" className="page-content">
            <div className="form-name">
              Welcome to {this.state.loading ? "true" : this.state.user}
              <span className="form-name-logo">
                [<span className="form-name-logo-mn">mn</span>]Todo
              </span>
            </div>
            <form>
              <div id="email" className="mb-3">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  name="email"
                  onChange={this.updateEmail}
                  placeholder="Enter your email address"
                />
                <div className="text-danger">
                  {this.state.emailConflictMsg}
                </div>
              </div>
              <div id="password" className="mb-3">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  name="password"
                  onChange={this.updatePassword}
                  placeholder="Enter your password"
                />
                <div className="text-danger">{this.state.passwordConflictMsg}</div>
              </div>
              <div className="row mb-3">
                <div className="col text-end">
                  <Link to="/login" id="login">Login ?</Link>
                </div>
              </div>
              <div>
                <button
                  id="loginBtn"
                  onClick={this.handleClick}
                  type="button"
                  className="btn btn-block btn-login btn-lg"
                >
                  Register
                </button>
                {
                  this.state.successMsg !=="" ? <div className="text-success"> <p> {this.state.successMsg} </p></div>:""
                }

              </div>
            </form>
          </div>
        </div>
        <div className="page-footer text-center">
          <p className="copyrights">
            <span>
              &#169;&nbsp;[<span className="mn-color">mn</span>]Todo
            </span>{" "}
            2022
            <br />
            Developed by&nbsp;[<span className="mn-color">mn</span>]media
            <strong>net</strong>
          </p>
        </div>
      </div>

    );
  }
}
const style = {
  margin: 15,
};
export default Register;