import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../css/reset.css";
import "../font-awesome/css/font-awesome.css";
import { SpinnerComponent } from 'react-element-spinner';

import { resetPassword,updateResetPasswordStatus } from '../redux/resetPassword/resetPasswordActions';
/**
 *
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    resetPasswordLoading: state.resetPassword.resetPasswordLoading,
    userData: state.resetPassword.userData,
    loginState: state.resetPassword.loginState,
    sucessMsg :state.resetPassword.sucessMsg,
    resetPasswordErrorMsg: state.resetPassword.resetPasswordErrorMsg,
    resetPasswordStatusCode: state.resetPassword.resetPasswordStatusCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (userid) => dispatch(resetPassword(userid)),
    updateResetPasswordStatus: (status) => dispatch(updateResetPasswordStatus(status))
  };
  };


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state =  { userid: "" };
  }

  componentDidUpdate = (prevProps, prevState, snapshot)=> {
    if(this.props.resetPasswordStatusCode === 200){
      this.props.history.push(
        {
          pathname:'/notice'
        });
    }
  }
  componentDidMount = () =>{
    this.props.updateResetPasswordStatus(0);
  }

  updatePassword = event => {
    this.setState({
      userid: event.target.value
    });
  };
  doResetPassword = () => {
    this.props.resetPassword(this.state.userid);
  };

  render() {
    return (
      <div id="reset" className="page">
        <SpinnerComponent loading={this.props.resetPasswordLoading}  position="global" color="#498DFF" />
        <div className="page-header">
          <span className="logo">[<span className="logo-mn">mn</span>]Todo</span>
          <span className="page-name-delimiter">-</span>
          <span className="page-name">Reset password</span>
        </div>
        <div className="page-body">
          <div id="passwordResetForm" className="page-content">
            <input type="hidden" name="length" value="10" />
            <div id="newPassword" className="form-group">
              <div
                className="input-group mb-3 input-group-lg"
                id="show_hide_password">
                <input
                  type="email"
                  id="password-field"
                  className="form-control form-control-lg"
                  onChange={this.updatePassword}
                  placeholder="Please enter youre email address"
                  name="user_email"
                />
              </div>
              <small className="help-block" id="password-text"></small>
            </div>
            <div id="saveSubmitdBtn" className="form-group">
              <button
                onClick={this.doResetPassword}
                type="button"
                className="btn btn-block btn-login btn-lg"
              >
                Submit
              </button>
              {
                this.props.errorMsg !=="" ? <div className="login-error"> <p> {this.props.resetPasswordErrorMsg} </p></div>:""
              }
            </div>
            <div className="text-end">
              <Link to="/login" id="login" className='loginLink' >Login ?</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect (mapStateToProps, mapDispatchToProps)(ResetPassword);