import React, {Component} from "react";
import axios from "axios";
import Todo from "./Todo";
import { BsPlus } from 'react-icons/bs';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import {ServiceURL} from '../redux/constants';
import { logout } from '../redux/login';
import { connect } from "react-redux";
import MNSTORE from "../redux/constants";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import "../App.css";

const mapStateToProps = state => {
  let {userData} = state.login ;
    if(!userData && localStorage.userData){ userData=localStorage.userData;
    userData = JSON.parse(userData);
    }
  return {
    userData
  };
};

const mapDispatchToProps = dispatch => {
  //debugger;
  return {
    logout: () => dispatch(logout())
  };
};
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state= {
      constraints:[],
      userid:'',
      selectedId:'',
    }

  }
  componentDidMount(){
    if(this.props.userData === "") {
      this.props.history.push(
        {
          pathname:'/login'
        });
    }
      const headers = {
        "mnauthorization": this.props.userData.data,
        "app-id-header":"2",
        "app-secret-header":"143",
      }
      if(this.props.userData.email) {
        var User = MNSTORE+"user/"+this.props.userData.email;
        axios.get(User,{headers:headers}).then(response=>{
          if(response.data.id){
            this.setState({
              ...this.state,
              userid: response.data.id,
            });
            const ConstraintURL = ServiceURL+"todo_api/getAllConstraints";
            axios.get(ConstraintURL).then(response=>{
              this.setState({
                constraints : response.data.constraints,
              });
            });
            const userId = this.state.userid;
            const ConstraintGetURL = ServiceURL+"todo_api/getConstraintByUserId?userId="+userId;
            axios.get(ConstraintGetURL).then(response=>{
              console.log("ikkada data",response.data);
              if(response.data.selectedId){
                this.setState({
                  selectedId:response.data.selectedId,
                })
              }
            })
          }
        });
      }

  }
   handleConstraint =(event)=>{
    const field_value = event.target.id;
    const userid = this.state. userid;
    const ConstraintURL = ServiceURL+"todo_api/updateConstraint";
    axios.post(ConstraintURL,{field_value,userid}).then(response=>{
      this.setState({
        selectedId:event.target.id,
      });

    });
    //console.log("constraint id ", field_value);

   }

  render() {
   // const { todos, selectTodo, selectedTodoId, openEditTodo } = this.state;
    //console.log("total todos : ",this.state.selectedId);
    return (
      <div>
        <Navbar className="todo-navbar" bg="light" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#Todolist" className="logo">[<span className="mn-color">mn</span>]Todo</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#Todolist">Home</Nav.Link>
                <Nav.Link href="#Settings">Settings</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Navbar.Text>
                Sample Test Cases for Constraint handling
              </Navbar.Text>
              <button
            id="logoutBtn"
            onClick={this.doLogout}
            type="button"
            className="btn btn-logout"
          >
            LogOut
          </button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="bodyContainer">
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center align-content-center p-3">
                <div className="todo-title">
                  <h4>Constraints</h4>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
                <Form>
                  <div className="p-3 text-left">
                    {this.state.constraints.map(constraint =>
                      <Form.Check
                        inline
                        type="radio"
                        name="constraint"
                        label={constraint.name}
                        id={constraint.id}
                        onChange={this.handleConstraint}
                        checked={constraint.id===this.state.selectedId?"checked":""}
                      />
                    )}
                  </div>
                </Form>
                <div className="Constraints-information">
                  <strong>Constraints types explanation</strong>
                  <ul>
                    <li>Type-1 It is Fixed type, one cannot allocate more time or budget than the time and budget allocated to parent todo. if we try to give more time and budget it will show error</li>
                    <li>Type-2 it is a flexible method, in which we can assign as much time and budget irrespective of parent values</li>
                    <li>Type-3 it talks in percentages, and it is also fixed type, we cannot assign more percentage than given parent values.</li>
                  </ul>
                </div>
            </Card.Body>
            </Card>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings) ;
