 const MNSTORE = "https://iam.dev.medianetsolutions.de/";
 //const ServiceURL = "http://localhost/mn_todo/index.php/";
 const ServiceURL = "https://todo.dev.medianetsolutions.de/services/index.php/";


//const MNSTORE = "http://localhost/";
//  export const MNSTORE = "http://user.mnqr.de/User_api/";
export default MNSTORE;
export{ ServiceURL};
/**
 * @constant
 * @method POST
 * @description This is used for end point of password reset phase by using this an email will be sent to valid emails for requesting password reset option
 */
//export const MNSTORE_PASSWORD_RESET_1 = MNSTORE + "passwordReset/createResetId";

/**
 * @constant
 * @method GET
 * @description This is 2nd phase of reset when user clicks on received email link this webservice is used to check the generated token, this webservice endpoint will be extended in actions by concatinating with {uniqueid}
 */
//export const MNSTORE_PASSWORD_RESET_2 =
//MNSTORE + "passwordReset/passwordReset/isCodeValid/";

/**
 * @constant
 * @method POST
 * @description this is final step of reset password like 2nd phase this end point also will be extended with unique id
 */
//export const MNSTORE_PASSWORD_RESET_3 = MNSTORE + "passwordReset/passwordReset";

/**uniqueId = password reset code[valid for 1 hour]
http://localhost/passwordReset/createResetId------[POST]-----params:[userid]
http://localhost/passwordReset/isCodeValid/{uniqueId}------[GET]-----params:[]
http://localhost/passwordReset/passwordReset/{uniqueId}------[POST]-----params:[password] */
