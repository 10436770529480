import React, { PureComponent } from "react";
import { Container, Dropdown, Table, Form, Row, Col,ListGroup, } from "react-bootstrap";
import { List,ThreeDots, Eye,Pencil,X,Trash,  SortAlphaUp, SortAlphaDownAlt} from 'react-bootstrap-icons'; //
import Pagination from 'react-bootstrap/Pagination'
import PropTypes from 'prop-types';
import '../../css/table.css';
import axios from "axios";

class MnTable extends PureComponent { 
  constructor(props) {
    super(props);
    this.state = { 
      sortCol : this.props.defaultSortColumn,
      totalCount:0,
      filterCount:0,
      loading: false,
      data: [],
      errorMsg: '',
      sortOrder: this.props.defaultSortValue,
      pageNumbers: [],
      pageNumber: 1,
      searchCols: this.props.searchColumns,
      pageSize: this.props.defaultPageSize,
      lastPageNumber: 1,
      columns: [],      
      allColumns: [],
      allColumnsDropDownHeight: 50,
      displayColumnsCount: 0,
      recordStarting: 1,
      recordEnding: 1,
      
    };
    this.columnInput = [];
    this.allColumns = [];
    this.displayColumns = [];
    this.searchKey = '';    
  }

  componentWillMount = () => {
       
  }

  componentDidMount = () => {
    this.fetchTableRecords(this.props.defaultSortColumn, this.props.defaultSortValue, this.searchKey, this.props.defaultPageSize, 1, this.props.dataURL, this.columnInput);
    

    var newColumns = [];
    for (let index = 0; index < this.props.columnNames.length; index++) {
      newColumns.push(this.props.columnNames[index]);
    }
    
    this.setState({
      columns: newColumns
    })
  }
  
  componentDidUpdate = () => {
    console.log("in componentDidUpdate", this.props);
    const tbodyHeight = document.getElementById('mn_tbody').clientHeight;
    this.setState({
      allColumnsDropDownHeight: tbodyHeight
    })
  }

  handleSorting = (colName, sortType) => {
    this.updateSortValue(sortType);
    colName = (sortType === 'default') ? this.props.defaultSortColumn : colName;
    sortType = (sortType === 'default') ? this.props.defaultSortValue : sortType;
    this.setState({
      sortCol: colName
    }, 
    this.fetchTableRecords(this.state.sortCol, this.state.sortOrder, this.searchKey, this.state.pageSize, this.state.pageNumber, this.props.dataURL, this.columnInput));
  }

  handleCommonSearch = (event) => {
    this.searchKey = event.target.value
    this.fetchTableRecords(this.state.sortCol, this.state.sortOrder, event.target.value, this.state.pageSize, 1, this.props.dataURL, this.columnInput)
    this.updatePageNumber(1);
  }

  handlePageRecords = (event) => {
    this.updatePageSize(event.target.value);
    this.fetchTableRecords(this.state.sortCol, this.state.sortOrder, this.searchKey, event.target.value, 1, this.props.dataURL, this.columnInput);
    this.updatePageNumber(1);
  }

  handlePagination = (pageNumber) => {
    console.log("this.props.pageSize ", this.state.pageSize);
    this.updatePageNumber(pageNumber);
    this.fetchTableRecords(this.state.sortCol, this.state.sortOrder, this.searchKey, this.state.pageSize, pageNumber, this.props.dataURL, this.columnInput)
  }

  handleColumnInput = (event) => {
    this.columnInput[this.props.searchColumns.indexOf(event.target.name)] = {[event.target.name]:event.target.value};
    this.fetchTableRecords(this.state.sortCol, this.state.sortOrder, this.searchKey, this.state.pageSize, this.state.pageNumber, this.props.dataURL, this.columnInput);
  }

  handleColumnToggle = (event) => {
    if(this.displayColumns.indexOf(event.target.name) === -1) {
      this.displayColumns.push(event.target.name);
      this.setState({
        displayColumns: this.displayColumns.length
      })
    } else {
      if(this.displayColumns.length > 1) {
        this.displayColumns.splice(this.displayColumns.indexOf(event.target.name), 1);
      } else {
        this.displayColumns = [];
        this.state.allColumns.map((column, key) => {
          this.displayColumns.push(column);
          return true;
        });
      }
      this.setState({
        displayColumns: this.displayColumns.length
      })
    }
  }

  newHandleToggleColumn = (event) => {
    console.log("newHandleToggleColumn", event.target);
  }

  updatePageSize = (statePageSize) => {
    this.setState({
      pageSize:statePageSize
    });
  }

  updateSortValue = (sortType) => {
    this.setState({
      sortOrder: sortType
    })
  }
  updateColumnNames = (columnNames) => {
    this.setState({
      columns:columnNames
    })
    this.displayColumns = columnNames
  }

  updatePageNumber = (statePageNumber) => {
    this.setState({
      pageNumber: statePageNumber
    })
  }

  setAllColumnNames = (columnNames) => {
    this.setState({
      allColumns: columnNames
    })
    columnNames.map((columnName, key) => {
      if(this.displayColumns.indexOf(columnName) !== -1) {
        this.allColumns[key] = {name: columnName, isChecked: true}
      } else {
        this.allColumns[key] = {name: columnName, isChecked: false}
      }
      return true;
    });
  }

  fetchTableRecords = (
    sortBy,
    sortOrder,
    globalSearch,
    pageLength,
    pageNumber,
    dataURL,
    colSearch = []
  ) => {
    var data = "";
    data = "rows=" + pageLength + "&pageno=" + pageNumber;
    if (sortBy !== "" && sortOrder !== "") {
      data += "&sortby=" + sortBy + "&sort_order=" + sortOrder;
    }
    data += "&keywords=" + globalSearch;
    colSearch.map((searchColum, index) => {
      for (const [key, value] of Object.entries(searchColum)) {
        data += "&" + key + "=" + value;
      }
      return true;
    });
    
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    axios
      .get(dataURL + "?" + data)
      .then(response => {
        console.log("table records req success", response);
        var filterRecords = response.data.filterRecords;
        var lastPage = Math.ceil(filterRecords / pageLength);
        var pageNos = [];
        for (var i = 1; i <= lastPage; i++) {
          pageNos.push(i);
        }
        var data = response.data.data;
        var recordEnding = 1;
        if(pageNumber === lastPage) {
          recordEnding = (pageLength * pageNumber) - pageLength + data.length;
        } else {
          recordEnding = (pageLength * pageNumber);
        }
        
        this.setState({
          ...this.state,
          data : data,
          lastPageNumber: lastPage,
          totalCount: response.data.totalRecords,
          filterCount: filterRecords,
          pageNumbers: pageNos,
          recordStarting: (pageLength * pageNumber) - pageLength + 1,
          recordEnding: recordEnding
        });
        if (this.props.columnNames.length === 0 && data.length > 0) {
          this.updateColumnNames(Object.keys(data[0]));
        }
        else {
          this.updateColumnNames(this.props.columnNames);
        }
        if (data.length > 0) {
          this.setAllColumnNames(Object.keys(data[0]));
        }
      })
      .catch(error => {
        console.log("request failed", error);
        this.setState({
          ...this.state,
          errorMsg: "Something went wrong while getting records",
          data: [],
          filterCount: 0,
          recordEnding: 0,
          recordStarting: 0
        })
      });
    
  }
  render() {
    return (
      <div className="page-table">
        <div className="tableTitle"> <h2> {this.props.title}</h2> </div>
        <Container fluid>
          <div>
            <input type="text" name="global_search" onChange={this.handleCommonSearch} className='mn_global_search ' />
          </div>
          <div className="emptyRow"></div>
          <Table id="table" responsive striped bordered hover size="sm" className="table-fit">
            <thead>
            <tr>
              <th></th>
                {
                  this.state.allColumns.map((colHeader, key) => {
                    var columnHeader = "";
                    this.displayColumns.indexOf(colHeader) !== -1 ? columnHeader = 
                      <th key={key} colname={colHeader} onClick={
                        this.state.sortOrder === 'DESC' ? () => this.handleSorting(colHeader, 'default'):
                          this.state.sortOrder === 'default' ? () => this.handleSorting(colHeader, 'ASC') 
                          : this.state.sortOrder === 'ASC' ? () => this.handleSorting(colHeader, 'DESC'):  ''} >
                        {colHeader}
                        <span className="pull-right">
                          {
                            this.state.sortCol === colHeader && this.state.sortOrder === 'ASC' ? <SortAlphaUp /> : this.state.sortCol === colHeader && this.state.sortOrder === 'DESC' ? <SortAlphaDownAlt /> : <i className="fa fa-sort"></i>
                          }
                        </span></th>
                        :
                        columnHeader = 
                      <th key={key} colname={colHeader} onClick={
                        this.state.sortOrder === 'DESC' ? () => this.handleSorting(colHeader, 'default'):
                          this.state.sortOrder === 'default' ? () => this.handleSorting(colHeader, 'ASC') 
                          : this.state.sortOrder === 'ASC' ? () => this.handleSorting(colHeader, 'DESC'):  ''} style={{display:'none'}}>
                        {colHeader}
                        <span className="pull-right">
                          {
                            this.state.sortCol === colHeader && this.state.sortOrder === 'ASC' ? <SortAlphaUp /> : this.state.sortCol === colHeader && this.state.sortOrder === 'DESC' ? <SortAlphaDownAlt /> : <i className="fa fa-sort"></i>
                          }
                        </span></th>
                    return columnHeader;
                  })
                }
                <th className="text-right">
                  <Dropdown className="customeDropdown">
                    <Dropdown.Toggle size="sm" variant="secondary" title="Actions" id="dropdown-basic">
                      <List />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{height:this.state.allColumnsDropDownHeight,overflow:'auto'}}>
                      <ListGroup variant="flush">
                        {
                          this.state.allColumns.map((colHeader, key) => {
                            return (
                              <ListGroup.Item key={key} onClick={this.newHandleToggleColumn}>
                                {
                                  (this.displayColumns.indexOf(colHeader) !== -1) ? <label><input type="checkbox" name={colHeader}        onChange={this.handleColumnToggle}  checked={true} /> {colHeader} </label> :
                                    <label><input type="checkbox" name={colHeader} onChange={this.handleColumnToggle} checked={false} />{colHeader} </label>
                                }
                                {/* &nbsp; {colHeader} */}
                              </ListGroup.Item>
                            );
                          })
                        }
                      </ListGroup>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
              </tr>
              <tr>
                <th></th>
                {
                  this.state.allColumns.map((colHeader, key) => {
                    return (
                      (this.state.searchCols.indexOf(colHeader) !== -1 && this.displayColumns.indexOf(colHeader) !== -1) ? 
                        <th key={key}><Form.Control size="sm" name={colHeader} onChange={this.handleColumnInput} className='mn_col_search' /></th> 
                          : (this.state.searchCols.indexOf(colHeader) !== -1 && this.displayColumns.indexOf(colHeader) === -1) ? <th key={key} style={{display:'none'}}><Form.Control size="sm" name={colHeader} onChange={this.handleColumnInput} className='mn_col_search' /></th> : (this.state.searchCols.indexOf(colHeader) === -1 && this.displayColumns.indexOf(colHeader) !== -1) ? <th key={key}></th> : (this.state.searchCols.indexOf(colHeader) === -1 && this.displayColumns.indexOf(colHeader) === -1) ? <th key={key} style={{display:'none'}}></th> : ''
                    );
                  })
                }
                <th>-</th>
              </tr>
            </thead>
            <tbody id="mn_tbody">
              {  this.state.data.length !== 0 ?
                this.state.data.map((record, key) => {
                  var trow = <tr key={key}>
                    <td>
                      <Form.Check type="checkbox" id="" key={key}>
                        <Form.Check.Input type="checkbox" className="mn_row_select" value="" onClick={this.handleTable} rowid={key} />
                        <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>
                      </Form.Check>
                    </td>
                    {this.state.allColumns.map((colName, i) => {
                      var thtd = '';
                      this.displayColumns.indexOf(colName) !== -1 ?
                      thtd = <td key={i}> {record[colName]} </td> :
                      thtd = <td key={i} style={{display:'none'}}> {record[colName]} </td>;
                      return thtd;
                    })}
                    <td className="text-right">
                      <Dropdown className="customeDropdown">
                        <Dropdown.Toggle size="sm" variant="secondary" title="Actions" id="dropdown-basic">
                          <ThreeDots />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1" className="text-default"><Eye />&nbsp;View</Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className="text-primary"><Pencil />&nbsp;Edit</Dropdown.Item>
                          <Dropdown.Item href="#/action-3" className="text-warning"><X />&nbsp;Cancel</Dropdown.Item>
                          <Dropdown.Item href="#/action-4" className="text-danger"><Trash />&nbsp;Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                  return trow;
                }) : <tr><td className="mnEmptyRecords" colSpan={this.state.columns.length + 2}>No Records Found</td></tr>
              }
            </tbody>
          </Table>
          <div className="page-filters">
            <Row>
              <Col>
                <div className="pageInfo">
                  showing {this.state.recordStarting} To {this.state.recordEnding} records of {this.state.totalCount}.
                </div>
              </Col>
              <Col xs={6} className="pagination-list">
                <Pagination size="lg">
                  {this.state.pageNumber === 1 ? <Pagination.First disabled /> : <Pagination.First onClick={() => this.handlePagination(1)} />}
                  {this.state.pageNumber === 1 ? <Pagination.Prev disabled /> : <Pagination.Prev onClick={() => this.handlePagination(this.state.pageNumber - 1)} />}
                  {
                    this.state.pageNumbers.map((pageNo, key) => {
                      return ((this.state.pageNumber === pageNo) ? <Pagination.Item key={key} active>{pageNo}</Pagination.Item> :
                        <Pagination.Item key={key} onClick={() => this.handlePagination(pageNo)}>{pageNo}</Pagination.Item>);
                    })
                  }
                  {this.state.pageNumber === this.state.lastPageNumber ? <Pagination.Next disabled /> : <Pagination.Next onClick={() => this.handlePagination(this.state.pageNumber + 1)} />}
                  {this.state.pageNumber === this.state.lastPageNumber ? <Pagination.Last disabled /> : <Pagination.Last onClick={() => this.handlePagination(this.state.lastPageNumber)} />}
                </Pagination>
              </Col>
              <Col xs={{ span: 2, offset: 1 }} className="justify-content-end">
                <Form inline>
                  <Form.Group controlId="formGridState">
                    <Form.Label className="my-1 mr-2">Entries per page: </Form.Label>
                    <Form.Control as="select" size="sm" className="w100 mn_pagination" onChange={this.handlePageRecords} defaultValue={this.state.pageSize}>
                      {
                        this.props.pageSizeValues.map((value, key) => {
                          return (<option value={value} key={key}>{value}</option>);
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
MnTable.propTypes = {
  data: PropTypes.array, 
  title: PropTypes.string,
  dataURL: PropTypes.string,
  commonSearch: PropTypes.bool,
  searchColumns: PropTypes.array,
  sortables: PropTypes.array,
  defaultSortColumn: PropTypes.string,
  defaultSortValue: PropTypes.string,
  defaultPageSize: PropTypes.number,
  pageSizeValues: PropTypes.array,
  firstPage: PropTypes.bool,
  previousPage: PropTypes.bool,
  nextPage: PropTypes.bool,
  lastPage: PropTypes.bool,
};

MnTable.defaultProps = {
  data: [],
  title:'',
  dataURL: '',
  commonSearch: true,
  searchColumns: [],
  sortables: [],
  defaultSortColumn: 'id',
  defaultSortValue: 'DESC',
  defaultPageSize: 10,
  pageSizeValues: [10,20,30,50],
  firstPage: false,
  previousPage: false,
  nextPage: false,
  lastPage: false,

};

export default MnTable;