import MNSTORE from "../constants";

export const RESETPASSWORD2_REQUEST = "RESETPASSWORD2_REQUEST";
export const RESETPASSWORD2_SUCCESS = "RESETPASSWORD2_SUCCESS";
export const RESETPASSWORD2_FAILURE = "RESETPASSWORD2_FAILURE";

export const RESETPASSWORD2UPDATE_REQUEST = "RESETPASSWORD2UPDATE_REQUEST";
export const RESETPASSWORD2UPDATE_SUCCESS = "RESETPASSWORD2UPDATE_SUCCESS";
export const RESETPASSWORD2UPDATE_FAILURE = "RESETPASSWORD2UPDATE_FAILURE";

/**
 * @constant
 * @method GET
 * @description This is used for end point of the resetpassword2 webservice
 */
export const MNSTORE_RESETPASSWORD2 = MNSTORE + "reset";

/**
 * @constant
 * @method POST
 * @description This is used for end point of the resetpassword2 update webservice
 */
export const MNSTORE_RESETPASSWORD2UPDATE = MNSTORE + "reset";