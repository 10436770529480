import axios from "axios";
import {
  RESETPASSWORD2_REQUEST,
  RESETPASSWORD2_SUCCESS,
  RESETPASSWORD2_FAILURE,
  RESETPASSWORD2UPDATE_REQUEST,
  RESETPASSWORD2UPDATE_SUCCESS,
  RESETPASSWORD2UPDATE_FAILURE,
  MNSTORE_RESETPASSWORD2,
  MNSTORE_RESETPASSWORD2UPDATE
} from "./resetPassword2Types";

const resetPassword2Request = () => {
  return {
	type: RESETPASSWORD2_REQUEST
  };
};

const resetPassword2Success = (success) => {
  return {
	type: RESETPASSWORD2_SUCCESS,
	payload: success
  };
};

const resetPassword2Failure = error => {
  return {
	type: RESETPASSWORD2_FAILURE,
	payload: error
  };
};

const resetPassword2UpdateRequest = () => {
  return {
	type: RESETPASSWORD2UPDATE_REQUEST
  };
};

const resetPassword2UpdateSuccess = (success) => {
  return {
	type: RESETPASSWORD2UPDATE_SUCCESS,
	payload: success
  };
};

const resetPassword2UpdateFailure = error => {
	return {
	type: RESETPASSWORD2UPDATE_FAILURE,
	payload: error
  };
};

export const resetPassword2Update = (password,token) => {
  return dispatch => {
	dispatch(resetPassword2UpdateRequest());
	var data = "password=" +password ;
	axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	axios
	  .post(MNSTORE_RESETPASSWORD2UPDATE + "/"+ token, data)
	  .then(response => {
		dispatch(resetPassword2UpdateSuccess(response));
	  })
	  .catch(error => {
		const errorMsg = error.response;
		dispatch(resetPassword2UpdateFailure(errorMsg));
	  });
  };
};

export const resetPassword2TokenCheck = (token) => {
  return dispatch => {
	dispatch(resetPassword2Request());  
	axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	axios
	  .get(MNSTORE_RESETPASSWORD2 +"/"+ token)
	  .then(response => {
		dispatch(resetPassword2Success(response));
	  })
	  .catch(error => {
		const errorMsg = error.response;
		dispatch(resetPassword2Failure(errorMsg));
	  });
  };
};
