import React, {Component} from "react";
import axios from "axios";
import Todo from "./Todo";
import { BsPlus } from 'react-icons/bs';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import {ServiceURL} from '../redux/constants';
import { logout } from '../redux/login';
import { connect } from "react-redux";
import MNSTORE from "../redux/constants";


const mapStateToProps = state => {
  console.log("First state",state);
  return {
    userData: state.login.userData
  };
};

const mapDispatchToProps = dispatch => {
  //debugger;
  return {
    logout: () => dispatch(logout())
  };
};

class TodoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [
        {
          id: 1,
          name: "",
          parent: 0,
          progress:"",
          constraints: [
            {
              id: 1,
              name: "Time",
              value: 50,
              units: "Hrs",
              type: 1
            },
            {
              id: 2,
              name: "Budgets",
              value: 100,
              units: "€",
              type: 1
            },

          ],
          subTask: []
        }
      ],
      openEditTodo: false,
      totalTodos: 1,
      conflict: false,
      conflictMsg: "",
      userid:'',
      users:[],
      selectedConstraintId:'',
    };
  }
  componentDidMount(){

    let {userData} = this.props ;
    if(!userData && localStorage.userData){ userData=localStorage.userData;
    userData = JSON.parse(userData);
    }
    console.log("madhyalo", userData.data);
    //return;
    if(!userData) {
      this.props.history.push(
        {
          pathname:'/login'
        });
    }
      const headers = {
        "mnauthorization": userData.data,
        "app-id-header":"2",
        "app-secret-header":"143",
      }
      if(userData.email){
      var User = MNSTORE+"user/"+userData.email;
      axios.get(User,{headers:headers}).then(response=>{
        if(response.data.id){
        this.setState({
          ...this.state,
          userid: response.data.id,
        });
        var UsersURL = MNSTORE+"user";
        axios.get(UsersURL,{headers:headers}).then(response =>{
          const users = response.data;
          users.forEach((user)=> {user.value=user.id; user.label=user.userid; return user;});
          this.setState({
            ...this.state,
            users,
          }, () =>
          console.log("all users",users)
          )
        })
        var TodoURL = ServiceURL+"todo_api/getAllTodos?userid="+response.data.id;
        axios.get(TodoURL).then(response=>{
            console.log("all todos : ",response.data);
          this.setState({
            ...this.state,
            todos: response.data.todos,
          });
        });
        const userId = this.state.userid;
        const userConstraintURL = ServiceURL+"todo_api/getConstraintByUserId?userId="+userId;
        axios.get(userConstraintURL).then(response=>{
          console.log("response data",response.data);
          if(response.data.selectedId){
            this.setState({
              selectedConstraintId:response.data.selectedId,
            });
          }

        });
        }
      });
      }
      console.log("check Users:",this.state.users)
  }
   componentDidUpdate = () => {
    let {userData} = this.props ;
    if(!userData && localStorage.userData){ userData=localStorage.userData;
    userData = JSON.parse(userData);
    }
    if(!userData) {
      this.props.history.push(
        {
          pathname:'/login'
        });
    }
  }

  addTodo = () => {
    var addURL = ServiceURL+"todo_api/create";
    const name = "";
    const parent = 0;
    const created_by = this.state.userid;

    //debugger;
    const headers = {
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Allow-Headers":"*",
      }
    const constraints = [{"id":1, "value":0,"name": "Time","units": "Hrs"},{"id":2, "value":0,"name": "Budgets","units": "€"}];
    axios.post(addURL,{name, parent,created_by, constraints},{headers:headers}).then(response=>{
    //const newTotalTodos = this.state.totalTodos + 1;
    const newObj = {
      id: response.data.todoId,
      name: "",
      structure: this.state.todos.length+1,
      parent: 0,
      progress:"",
      constraints: [
        {
          id: 1,
          name: "Time",
          value: 0,
          units: "Hrs",
          type: this.state.selectedConstraintId,
        },
        {
          id: 2,
          name: "Budgets",
          value: 0,
          units: "€",
          type: this.state.selectedConstraintId
        },

      ],
      subTask: []
    };
    const { todos } = this.state;
    const newTodos = [...todos];
    newTodos.push(newObj);
    this.setState({
      ...this.state,
      todos: newTodos,
     // totalTodos: newTotalTodos
    });
  });
  };
getStrucutre = todoId =>{
   for(let j=0;this.state.todos.length;j++)
  {
    let todo = this.state.todos[j];
    if(todo.id == todoId){
          return todo.strucutre+'.'+todo.subTask.length;
     }else if (todo.subTask.length!==0){
          var i;
          var result = null;
          console.log(todo.subTask.length, todo.id, todoId);
          for(i=0; result == null && i < todo.subTask.length; i++){
              return  result = this.getStrucutre(todo.subTask[i], todoId);
          }
          return result;
     }

  }

}
  addSubTodo = (todoId, todo) => {
    console.log("In todo List page:",todoId);
    var addURL = ServiceURL+"todo_api/create";
    const name = "";
    const created_by = this.state.userid;
    const parent = todoId;

    const constraints = [{"id": 1,  "value": 0,"name": "Time","units": "Hrs" },{"id": 2, "value": 0,"name": "Budgets","units": "€"}];
    console.log("todo present",todo);
    axios.post(addURL,{name, parent,created_by, constraints}).then(response => {
    //const newTotalTodos = this.state.totalTodos + 1;
    const newObj = {
      id: response.data.todoId,
      name: "",
      structure:todo.structure+"."+(todo.subTask.length+1),
      parent: todoId,
      progress:"",
      constraints: [
        {
          id: 1,
          name: "Time",
          value: 0,
          units: "Hrs",
          type: this.state.selectedConstraintId
        },
        {
          id: 2,
          name: "Budgets",
          value: 0,
          units: "€",
          type: this.state.selectedConstraintId
        },

      ],
      subTask: []
    };
    const { todos } = this.state;
    const newTodos = this.findAndAddSubTodo(todoId, [...todos], newObj);
    this.setState({
      todos: newTodos,
      //totalTodos: newTotalTodos
    });
  });
  };

  findAndAddSubTodo = (todoId, arrayOfObjects, newObject) => {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].id === todoId) {
        arrayOfObjects[i].subTask.push(newObject);
      } else if (arrayOfObjects[i].subTask.length > 0) {
        arrayOfObjects[i].subTask = this.findAndAddSubTodo(
          todoId,
          arrayOfObjects[i].subTask,
          newObject
        );
      }
    }
    return arrayOfObjects;
  };

  findAndReplaceTodo = (todoId, arrayOfObjects, newObj) => {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].id === todoId) {
        arrayOfObjects[i] = newObj;
      } else if (arrayOfObjects[i].subTask.length > 0) {
        arrayOfObjects[i].subTask.subTask = this.findAndReplaceTodo(
          todoId,
          arrayOfObjects[i].subTask,
          newObj
        );
      }
      return arrayOfObjects;
    }
  };

  type3AvailableConstraintLimit = (
    todoId,
    parentId,
    constraintId,
    newValue
  ) => {
    const { todos } = this.state;
    const parentConstraintValue = this.getParentConstraintValue(parentId,constraintId);
    const parentObj = this.getObjectbyId(parentId, todos);
    let sumOfConstraints = 0;
    let constraintName = "";
    let subConstraint = {};
    if (parentId === 0) {
      return false;
    }
    if(parentObj)for (let todo of parentObj.subTask) {
      if (todo.id !== todoId) {
        subConstraint = todo.constraints.filter(
          constraint => constraint.id === constraintId
        );
        sumOfConstraints += subConstraint[0].value;
        constraintName = subConstraint[0].name;
      }
    }

    sumOfConstraints += newValue;
    console.log(parentConstraintValue ,sumOfConstraints);
    if (parentConstraintValue < sumOfConstraints) {
      let conflictErrorMsg =
        "Conflict: you are exceeding parent " +
        constraintName +
        "(" +
        parentConstraintValue +
        ") Contact Todo assigner.";
      this.setState({
        conflict: true,
        conflictMsg: conflictErrorMsg
      });
      return true;
    }
    this.setState({
      conflict: false,
      conflictMsg: ""
    });
    return false;
  };

  checkAvailableConstraintLimit = (
    todoId,
    parentId,
    constraintId,
    newValue
  ) => {
    const { todos } = this.state;
    const parentConstraintValue = this.getParentConstraintValue(
      parentId,
      constraintId
    );
    const parentObj = this.getObjectbyId(parentId, todos);
    let sumOfConstraints = 0;
    let constraintName = "";
    let subConstraint = {};
    if (parentId === 0) {
      return false;
    }
    if(parentObj)
    for (let todo of parentObj.subTask) {
      if (todo.id !== todoId) {
        subConstraint = todo.constraints.filter(
          constraint => constraint.id === constraintId
        );
        sumOfConstraints += subConstraint[0].value;
        constraintName = subConstraint[0].name;
      }
    }

    sumOfConstraints += newValue;
    if (parentConstraintValue < sumOfConstraints) {
      let conflictErrorMsg =
        "Conflict: you are exceeding parent " +
        constraintName +
        "(" +
        parentConstraintValue +
        ") Contact Todo assigner.";
      this.setState({
        conflict: true,
        conflictMsg: conflictErrorMsg
      });
      return true;
    }
    this.setState({
      conflict: false,
      conflictMsg: ""
    });
    return false;
  };
  getParentConstraintValue = (
    parentId,
    constraintId,
    todos = this.state.todos
  ) => {
    for (let todo of todos) {
      console.log(todo);
      if (todo.id === parentId) {
        const constraint = todo.constraints.filter(
          constraint => constraint.id === constraintId
        );

        return constraint[0].value;
      } else
     {
       //if(todo.subTask!==undefined)
        if (todo.subTask.length > 0) {
        return this.getParentConstraintValue(
          parentId,
          constraintId,
          todo.subTask
        );
      }
    }
    }
  };
    getSubtaskStatus = (todoId)=>{
      const {todos} = this.state;
      const todos_new = this.getObjectbyId(todoId, todos);
      //console.log("ikkada todos:",todoId);
      if(todos_new &&todos_new.subTask.length>0){
      for(let todo of todos_new.subTask){
      //console.log("list page lo:",todo);
        if(todo.status!=5){
          this.setState({
            conflict: true,
            conflictMsg: "Cannot close main todo without closing sub todos."
          });
          return false;
        }
      }return true;

    }else {
      this.setState({
            conflict: false,
            conflictMsg: ""
          });
        return true;
    }
    }
  getObjectbyId = (todoId, obj) => {
    console.log("getobject lo todos",obj);
    for (let todo of obj) {
      if (todo.id === todoId) {
        return todo;
      } else if (todo.subTask.length > 0) {
        return this.getObjectbyId(todoId, todo.subTask);
      }
    }
  };

  updateTodo = (todoId, newObj) => {

    /*var updateURL = ServiceURL+"todo_api/todoUpdate";
    const field_name = "constraints";
    const field_value=newObj.constraints;
    axios.post(updateURL,{field_name, field_value, todo_id});*/
    //console.log("change ayyaka",newObj);
    const todo_id= todoId;
    const { todos } = this.state;
    const newTodos = this.findAndReplaceTodo(todoId, [...todos], newObj);
    this.setState({
      todos: newTodos
    });
  };
  deleteTodo = (todoId, todo, todos)=>{

    const userid = this.state.userid;
    var deleteURL = ServiceURL+"todo_api/todoDelete";
    axios.post(deleteURL,{todoId,userid}).then(response=>{
     // console.log("ippude vacha",response);
    this.setState({
      todos: response.data.todos
    });
    });


  }
  sampleFunction = (todoId,todo)=>{
    console.log("idento");
  }
  doLogout = () => {
    this.props.logout();
  };
  closeConflict=()=>{
    this.setState({
      conflict:false,
      conflictMsg:""
    });
  }

  handleEditTodo = todo => {
   };
  render() {
    const { todos, selectTodo, selectedTodoId, openEditTodo } = this.state;
    console.log("total todos : ",this.state.selectedConstraintId);
    return (
      <div>
        <Navbar className="todo-navbar" bg="light" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#home" className="logo">[<span className="mn-color">mn</span>]Todo</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#Settings">Settings</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Navbar.Text>
                Sample Test Cases for Constraint handling
              </Navbar.Text>
              <button
            id="logoutBtn"
            onClick={this.doLogout}
            type="button"
            className="btn btn-logout"
          >
            LogOut
          </button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="bodyContainer">
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center align-content-center">
                <div className="todo-title">
                  <h4>Todos List</h4>
                </div>
                <div>
                  <Button  className="ms-2 btn btn-success btn-lg" onClick={todoId => this.addTodo(todoId)}><BsPlus size={24} />&nbsp;Add new Todo</Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="todoListUL">
                <ListGroup>
                  { todos && todos.map(todo => (
                    <Todo
                      deleteTodo = {(todoId, todo)=>this.deleteTodo(todoId, todo, todos)}
                      selectedConstraintId = {this.state.selectedConstraintId}
                      closeConflict = {()=>this.closeConflict()}
                      conflict={this.state.conflict}
                      conflictMsg ={this.state.conflictMsg}
                      todo={todo}
                      addSubTodoFromTodoList={(newTodoId,newTodo) => this.addSubTodo(newTodoId, newTodo)}
                      assignUsers={this.state.users}
                      key={todo.id}
                      handleEditTodo={todoObj => this.handleEditTodo(todoObj)}
                      getSubtaskStatus ={(todoId=> this.getSubtaskStatus(todoId))}
                      checkAvailableConstraintLimit={(todoId,
                        parentId,
                        constraintId,
                        newValue) => this.checkAvailableConstraintLimit(todoId, parentId, constraintId,newValue)}
                        type3AvailableConstraintLimit={(
                          todoId,
                          parentId,
                          constraintId,
                          newValue
                        ) =>
                          this.type3AvailableConstraintLimit(
                            todoId,
                            parentId,
                            constraintId,
                            newValue
                          )
                        }
                      updateTodo={(todoId, newObj) => this.updateTodo(todoId, newObj)}
                      sampleFunction = {(todoId, todo)=>this.sampleFunction(todoId, todo)}
                    />
                  ))}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TodoList) ;
