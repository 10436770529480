import axios from "axios";
import {
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILURE,
  MNSTORE_RESETPASSWORD,
  UPDATE_RESET_PASSWORD_STATUS
} from "./resetPasswordTypes";

const resetpasswordRequest = () => {
  return {
    type: RESETPASSWORD_REQUEST
  };
};

const resetpasswordSuccess = userData => {
  return {
    type: RESETPASSWORD_SUCCESS,
    payload: userData
  };
};

const resetpasswordFailure = error => {
  return {
    type: RESETPASSWORD_FAILURE,
    payload: error
  };
};

export const updateResetPasswordStatus = (status) =>{
  return {
    type: UPDATE_RESET_PASSWORD_STATUS,
    payload:status
  }
}

export const resetPassword = (userid) => {
  return dispatch => {
    dispatch(resetpasswordRequest());
    var data = "userid=" +userid ;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios
      .post(MNSTORE_RESETPASSWORD, data)
      .then(response => {
        const users = response;
        dispatch(resetpasswordSuccess(users));
      })
      .catch(error => {
        const errorMsg = error.response;
        dispatch(resetpasswordFailure(errorMsg));
      });
  };
};
