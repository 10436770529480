import React, { Component } from "react";
import "../css/footer.css";
import "../css/style.css";
import "../css/login.css";
import { connect } from "react-redux";
import { logout } from '../redux/login'

const mapStateToProps = state => {
  return {
    userData: state.login.userData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};


class dashboard extends Component {
  componentDidMount = () => {
    console.log("this is new data"+this.props.userData);
    if(this.props.userData === "") {
      this.props.history.push(
        {
          pathname:'/login'
        });
    }
  }
  componentDidUpdate = () => {
    if(this.props.userData === "") {
      this.props.history.push(
        {
          pathname:'/login'
        });
    }
  }

  doLogout = () => {
    this.props.logout();
  };
	render() {
		return (
			<div id="reset" className="page">
				<div className="page-header">
					<span className="logo">[<span className="logo-mn">mn</span>]Shop</span>
          <button
            id="logoutBtn"
            onClick={this.doLogout}
            type="button"
            className="btn btn-logout"
          >
            LogOut
          </button>
				</div>
				<div className="page-body">
				    <div>		
					    <h1 className="alert-heading">Welcome to [mn]Shop</h1>
						<p>You have been successfully logged in.</p>
				    </div>
				</div>	
				<div className="page-footer text-center">
					<p className="copyrights">
						<span>&#169;&nbsp;[<span className="mn-color">mn</span>]Shop</span> 2020<br/>
						Developed by&nbsp;[<span className="mn-color">mn</span>]media<strong>net</strong>
					</p>
				</div>
			</div>	
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(dashboard);
