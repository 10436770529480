import React, { Component } from 'react';
import { connect } from "react-redux";
import "../css/reset.css";
import "../font-awesome/css/font-awesome.css";
import { resetPassword2TokenCheck, resetPassword2Update} from '../redux/resetPassword2/resetPassword2Actions';
import { SpinnerComponent } from 'react-element-spinner';

const mapStateToProps = state => {
    return {
      resetPassword2Loading: state.resetPassword2.resetPassword2Loading,
      resetPassword2TokenState: state.resetPassword2.resetPassword2TokenState,
      resetPassword2ErrorMsg: state.resetPassword2.resetPassword2ErrorMsg,
      resetPassword2errorStatus: state.resetPassword2.errorStatus,
      resetPassword2updateStatus: state.resetPassword2.updateStatus,
    };
  };  
const mapDispatchToProps = dispatch => {
    return {
        resetPassword2TokenCheck: (token) => dispatch(resetPassword2TokenCheck(token)),
        resetPassword2Update: (password,token) => dispatch(resetPassword2Update(password,token))
    };
  };

class ResetPassword2Component extends Component {
    constructor(props) {
        super(props);
        this.state = { password: "", 
        token:"", 
        validationErrorMsg:true,
        showPasswordSwitch:false,
        passwordLengthCase:false,
        passwordUpperCase:false,
        passwordLowerCase:false,
        passwordNumberCase:false,
        passwordSpecialCase:false,
        passwordNotSpecialCase:false,
        submitEnable:true,

     };
    }
    componentDidMount = () => {
        let { location } = this.props;
        let locationArray = location.pathname.split("/");
        let token = locationArray[2];
        this.props.resetPassword2TokenCheck(token);
    }

    componentDidUpdate =() =>{
        if(this.props.resetPassword2updateStatus === 200){
            this.props.history.push({
                pathname:'/login'
            });
        }
    }

    validatePassword = () =>{
        let lengthCase = new RegExp("^(?=.{8,})");
        let lowerCase =  new RegExp("^(?=.*[a-z])");
        let upperCase =  new RegExp("^(?=.*[A-Z])");
        let numberCase =  new RegExp("^(?=.*[0-9])");        
        let specialCase =  new RegExp('^(?=.*[!@#$%^&*-])');
        let notSpecialCase = new RegExp("^.*[^a-z-A-Z-0-9!@#$%^&*-]");
        
        lengthCase.test(this.state.password)? this.setState({ passwordLengthCase:true }) : this.setState({passwordLengthCase:false})

        lowerCase.test(this.state.password)? this.setState({ passwordLowerCase:true }) : this.setState({passwordLowerCase:false})

        upperCase.test(this.state.password)? this.setState({ passwordUpperCase:true }) : this.setState({passwordUpperCase:false})

        numberCase.test(this.state.password)? this.setState({ passwordNumberCase:true }) : this.setState({passwordNumberCase:false})
        
        specialCase.test(this.state.password)? this.setState({ passwordSpecialCase:true }) : this.setState({passwordSpecialCase:false})

        notSpecialCase.test(this.state.password)? this.setState({ passwordNotSpecialCase:false }) : this.state.password.length>0? this.setState({passwordNotSpecialCase:true}):this.setState({ passwordNotSpecialCase:false }) 

        if(lengthCase.test(this.state.password) && lowerCase.test(this.state.password) && upperCase.test(this.state.password) && numberCase.test(this.state.password) && specialCase.test(this.state.password) && !notSpecialCase.test(this.state.password)){
            this.setState({
                submitEnable:false
            })
        }
        else{
            this.setState({
                submitEnable:true
            })
        }


     //   notAllowedCase.test(this.state.password)? this.setState({ passwordSpecialCase:false }) : this.setState({passwordSpecialCase:true})
    }
    updatePassword = event => {
        this.setState({
          password: event.target.value
        },()=>{this.validatePassword()});

    };
    showPassword = event => {
        this.setState({
            showPasswordSwitch : !this.state.showPasswordSwitch
        })
    }

    doResetPassword2 = () => {
        this.props.resetPassword2TokenCheck(this.state.token);

    };

    doResetPassword2Update = (event) => {
        let validatePassword = this.state.password.match(/[a-z]/g) && this.state.password.match( 
            /[A-Z]/g) && this.state.password.match( 
            /[0-9]/g) && this.state.password.match( 
            /[^a-zA-Z\d]/g) && this.state.password.length >= 8
        
        if(validatePassword){
            let { location } = this.props;
            let locationArray = location.pathname.split("/");
            let token = locationArray[2];
            this.props.resetPassword2Update(this.state.password, token);
            this.setState({
                validationErrorMsg:true
            });
        }
        else{
            this.setState({
                validationErrorMsg:false
            });
        }

    };

    render() {
        return (
                        
            <div id="reset" className="page">
                <SpinnerComponent loading={this.props.resetPassword2Loading}  position="global" color="#498DFF" />
                <div className="page-header">
                    <span className="logo">[<span className="logo-mn">mn</span>]Shop</span>
                    <span className="page-name-delimiter">-</span>
                    <span className="page-name">Reset password</span>
                </div>
                <div className="page-body">
                    
                    { this.props.resetPassword2errorStatus === 200 ?
                    <div id="passwordResetForm" className="page-content">
                        
                            <input type="hidden" name="length" value="10" />
                            <div id="newPassword" className="form-group">
                                <div
                                    className="input-group mb-3 input-group-lg"
                                    id="show_hide_password"
                                >
                                <input
                                        type={this.state.showPasswordSwitch?"text":"password"}
                                        id="password-field"
                                        className="form-control form-control-lg"
                                        onChange={this.updatePassword}
                                        placeholder="Please enter the new password"
                                        name="row_password"
                                    />
                                    <div className="input-group-append" onClick={this.showPassword}>
                                        <span className="input-group-text" id="basic-addon2">
                                        <i className={this.state.showPasswordSwitch?"fa fa-eye":"fa fa-eye-slash"} aria-hidden="true"></i>
                                            </span>
                                    </div>
                                </div>
                                <small className="help-block" id="password-text"></small>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <div
                                        id="savePassword"
                                        className="form-group form-check showPassword-margin"
                                    >
                                    </div>
                                </div>
                            </div>
                            <div id="savePasswordBtn" className="form-group">
                                <button
                                    onClick={this.doResetPassword2Update}
                                    type="button"
                                    className="btn btn-block btn-login btn-lg"
                                    disabled = {this.state.submitEnable?true:false}
                                >
                                    Submit
                                </button>
                            </div>
                            <div id="password-note" className={this.state.validationErrorMsg? "form-group": "form-group validationError"}>
                                <p>
                                    Please set a new password for your account.
                                    The password has to follow the following rules:
                                </p>
                                <ul>
                                    <li className={this.state.passwordLengthCase?'valid':'notValid'}>
                                    <i className={this.state.passwordLengthCase?"fa fa-check":"fa fa-times"} aria-hidden="true"></i> Min. 8 characters long</li>
                                    <li className={this.state.passwordUpperCase?'valid':'notValid'}>
                                        <i className={this.state.passwordUpperCase?"fa fa-check":"fa fa-times"} aria-hidden="true"></i> Contains at least one upper-case character</li>
                                    <li className={this.state.passwordLowerCase?'valid':'notValid'}>
                                        <i className={this.state.passwordLowerCase?"fa fa-check":"fa fa-times"} aria-hidden="true"></i> Contains at least one lower-case character</li>
                                    <li className={this.state.passwordNumberCase?'valid':'notValid'}>
                                        <i className={this.state.passwordNumberCase?"fa fa-check":"fa fa-times"} aria-hidden="true"></i> Contains at least one number</li>
                                    <li className={this.state.passwordSpecialCase?'valid':'notValid'}>
                                        <i className={this.state.passwordSpecialCase?"fa fa-check":"fa fa-times"} aria-hidden="true"></i> Contains at least one special character</li>
                                    <li className={this.state.passwordNotSpecialCase?'valid':'notValid'}>
                                        <i className={this.state.passwordNotSpecialCase?"fa fa-check":"fa fa-times"} aria-hidden="true"></i> Allowed characters. [a-z][A-Z][0-9][!@#$%^*-] </li>
                                </ul>
                            </div>
                    </div> :"Invalid URL please check mail and use proper URL"}
                </div>
                <div className="page-footer text-center">
                    <p className="copyrights">
                        <span>&#169;&nbsp;[<span className="mn-color">mn</span>]Shop</span> 2020<br/>
                        Developed by&nbsp;[<span className="mn-color">mn</span>]media<strong>net</strong>
                    </p>
                </div>
            </div>
        );
    }
}

export default connect (mapStateToProps, mapDispatchToProps)(ResetPassword2Component);
